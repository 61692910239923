import styled from "styled-components"

const ContactUsContentContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: space-between;
`

const ContactUsFormCotainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
`

const ContactUsImageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    
    svg{
        height: 507px;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }
`

export { ContactUsContentContainer, ContactUsFormCotainer, ContactUsImageContainer }