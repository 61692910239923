import {
    ServiceItemContainer,
    ServiceItemHeader,
    ServiceItemHeaderTitle,
    ServiceItemHeaderDescriptionContainer,
    ServiceItemHeaderDescriptionText,
    ServiceItemContentContainer,
    ServiceItemContentItemContainer,
    ServiceItemContentItemTitle,
    ServiceItemContentItemList
} from "./serviceItemStyled"
import Button from "components/home/button/button"
import { useNavigate } from "react-router-dom" 

export default function ServiceItem({ title, description, items, image }) {
    const navigate = useNavigate()

    return (
        <ServiceItemContainer>
            <ServiceItemHeader>
                <ServiceItemHeaderTitle>
                    {title}
                </ServiceItemHeaderTitle>
                <ServiceItemHeaderDescriptionContainer>
                    <ServiceItemHeaderDescriptionText>
                        {description}
                    </ServiceItemHeaderDescriptionText>
                    <Button onClick={() => {
                        navigate('/contact-us')
                    }}>Learn More</Button>
                </ServiceItemHeaderDescriptionContainer>
            </ServiceItemHeader>
            <ServiceItemContentContainer>
                {
                    items.map(item => {
                        return (
                            <ServiceItemContentItemContainer>
                                <ServiceItemContentItemTitle>{item.title}</ServiceItemContentItemTitle>
                                <ServiceItemContentItemList>
                                    {
                                        item.elems.map(elem => {
                                            return <li><p>{elem}</p></li>
                                        })
                                    }
                                </ServiceItemContentItemList>
                            </ServiceItemContentItemContainer>
                        )
                    })
                } 
                { image ? image : null }
            </ServiceItemContentContainer>
        </ServiceItemContainer>
    )
}