import styled from "styled-components";
import Nav from "components/general/nav/nav";
import Footer from "components/general/footer/footer";
import Header from "components/general/header/header";
import { authorizedRequest, baseUrl } from "App";
import { useEffect, useState } from "react"; 
import { useNavigate } from "react-router-dom"; 

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px;

  .contact-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 70vh;
    overflow: scroll;
  }

  .contact{
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #fff;
    color: var(--text-primary);
  }

  @media screen and (max-width: 1080px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 70px;
  }
`;


const AppWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--background-primary);
`;


export default function Contacts() {
    const [ contacts, setContacts ] = useState([])
    const navigate = useNavigate()

    const getContacts = async () => {
        const response = await authorizedRequest(baseUrl + '/api/contact', 'GET')
        console.log(response)
        if(response && response.result){
            return response.result
        }

        return undefined
    }

    useEffect(() => {
        getContacts()
        .then((response) => {
            if(response){
                setContacts([...response])
            }else{
                localStorage.setItem('accessToken', '')
                navigate('/login')
            }
        })
    }, [])

    return (
        <AppWrapper>
            <Nav />
            <Header title='Contacts.' />
            <SectionWrapper>
                <div className="contact-container">
                    {
                        contacts.map((contact) => {
                            
                            const options = {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                timeZoneName: 'short'
                            };
                            
                            const formattedDate = new Date(contact.date * 1000).toLocaleDateString('en-US', options);
                            return (
                                <div className="contact">
                                    <p>{contact.name}</p>
                                    <p>{contact.email}</p>
                                    <p>{contact.phone_number}</p>
                                    <p>{contact.message}</p>
                                    <p>{formattedDate}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </SectionWrapper>
            <Footer />
        </AppWrapper>
    )
}