import { ContactUsContentContainer, ContactUsFormCotainer, ContactUsImageContainer } from "./contactUsContentStyled"
import TextArea from '../textArea/textArea'
import InputField from '../../general/input/input'
import Button from '../../general/button/button'
import { contactUsImage } from "assets/images/contactUsImages"
import { useState } from "react" 
import { unauthorizedRequest, baseUrl } from "App"

export default function ContactUsContent() {

    const addContact = () => {
        let check = true

        if(name === ''){
            errors.name = 'Enter your name please.'
            check = false 
        }
        
        if(phoneNumber == ''){
            errors.phoneNumber = 'Enter your phone number please.'
            check = false 
        }
        
        if(email == ''){
            errors.email = 'Enter your email please.'
            check = false 
        } 
        
        if(message == ''){
            errors.message = 'Enter your message please.'
            check = false 
        }

        setErrors({...errors})

        if(check){
            unauthorizedRequest(baseUrl + '/api/contact', 'POST', {
                name: name,
                phone_number: phoneNumber,
                email: email,
                message: message
            })
    
            setName('')
            setPhoneNumber('')
            setEmail('')
            setMessage('')
        }
    }

    const [errors, setErrors] = useState({
        name: undefined,
        phoneNumber: undefined,
        email: undefined,
        message: undefined
    })

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    return (
        <ContactUsContentContainer>
            <ContactUsFormCotainer>
                <InputField
                    label='Full Name'
                    placeholder='Input your full name'
                    onChange={(e) => {
                        setErrors({...errors, name: ''})
                        setName(e.target.value)
                    }}
                    value={name}
                    error={errors.name}
                />
                <InputField
                    label='Phone number'
                    placeholder='Input your phone number'
                    onChange={(e) => {
                        setErrors({...errors, phoneNumber: ''})
                        setPhoneNumber(e.target.value)
                    }}
                    value={phoneNumber}
                    error={errors.phoneNumber}
                />
                <InputField
                    label='Email'
                    placeholder='Input your email'
                    onChange={(e) => {
                        setErrors({...errors, email: ''})
                        setEmail(e.target.value)
                    }}
                    value={email}
                    error={errors.email}
                />
                <TextArea
                    label='Message'
                    placeholder='Input your message'
                    onChange={(e) => {
                        setErrors({...errors, message: ''})
                        setMessage(e.target.value)
                    }}
                    value={message}
                    error={errors.message}
                />
                <Button onClick={addContact}>Submit</Button>
            </ContactUsFormCotainer>
            <ContactUsImageContainer>
                {contactUsImage}
            </ContactUsImageContainer>
        </ContactUsContentContainer>
    )
}