import LogoStyle from "./logoStyled";
import { useNavigate } from "react-router-dom";

export default function Logo ({ size = 25 }) {
  const navigate = useNavigate()

  return (
    <LogoStyle size={size} onClick={() => {
      navigate('/')
    }}>
      <span>Web</span>
      <div>
        <svg
          width={size - 2}
          height={size - 3}
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.0226 17.2106H18.94L20.1693 14.752V19.6693H12.7933V15.5547C15.3122 14.4692 17.096 11.7672 17.096 8.60531C17.096 4.48706 14.0694 1.26252 10.3346 1.26252C6.59994 1.26252 3.57333 4.48583 3.57333 8.60531C3.57333 11.7672 5.35709 14.4692 7.87598 15.5547V19.6693H0.5V14.752L1.72933 17.2106H6.64665V16.5849C3.04226 15.3089 0.5 12.2171 0.5 8.60531C0.5 3.85272 4.90346 0 10.3346 0C15.7658 0 20.1693 3.85272 20.1693 8.60531C20.1693 12.2171 17.627 15.3076 14.0226 16.5849V17.2106Z" fill="#9C7339" />
        </svg>
        <span>lympus</span>
      </div>
    </LogoStyle>
  );
}