import Icon from "components/general/icon/icon";
import ContactSection from "./contactStyled";
import SectionName from "../sectionName/sectionNameStyled";
import SectionTitle from "../sectionTitle/sectionTitleStyled";
import linkDecoration from "assets/images/link-decoration.svg";
import { Link } from "react-router-dom"; 

export default function Contact () {
  return (
    <ContactSection id="contact">
      <SectionName>TELL US ABOUT YOUR PROJECT</SectionName>
      <SectionTitle>Let’s Make Something Great Together!</SectionTitle>
      <div className="link-wrapper">
        <Link to='/contact-us'>Contact Us</Link>
        <Icon name="arrow" />
        <img src={linkDecoration} alt="" className="link-decoration" />
      </div>
    </ContactSection>
  );
}