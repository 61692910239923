import styled from "styled-components";
import About from '../components/home/about/about';
import Contact from '../components/general/contact/contact';
import Hero from '../components/home/hero/hero';
import Portfolio from '../components/home/portfolio/portfolio';
import PortfolioItem from '../components/home/portfolioItem/portfolioItem';
import Services from '../components/home/services/services';
import Testimonials from '../components/home/testimonials/testimonials';
import Nav from "components/general/nav/nav";
import Footer from "components/general/footer/footer";

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px;


  @media screen and (max-width: 1080px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 70px;
  }
`;


const AppWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--background-primary);
`;

export default function Home () {
  return (
    <AppWrapper>
      <Nav />
      <Hero />
      <SectionWrapper>
        <About />
      </SectionWrapper>
      <Services />
      <SectionWrapper>
        <Portfolio />
      </SectionWrapper>
      <PortfolioItem />
      <SectionWrapper>
        <Testimonials />
      </SectionWrapper>
      <SectionWrapper>
        <Contact />
      </SectionWrapper>
      <Footer />
    </AppWrapper>
  );
}