import styled from "styled-components";
import Nav from "components/general/nav/nav";
import Footer from "components/general/footer/footer";
import Header from "components/general/header/header";
import ServiceItem from "components/services/serviceItem/serviceItem";
import { webDesign, webDevelopment } from "assets/images/serviceImages";

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px;
  display: flex;
  flex-direction: column;
  gap: 170px;

  @media screen and (max-width: 1080px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 70px;
  }
`;


const AppWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--background-primary);
`;


export default function Services() {
    return (
        <AppWrapper>
            <Nav />
            <Header title='Services.' />
            <SectionWrapper>
                <ServiceItem
                    title='Web Design / Redesign'
                    description='From ideation to graphicdesign, UX/UI design is the historical discipline of our agency.'
                    image={webDesign}
                    items={[
                        {
                            title: 'UX',
                            elems: ['UX Audit', 'Wireframes', 'User flows', 'Prototyping']
                        },
                        {
                            title: 'Websites',
                            elems: ['Wireframes', 'Art Direction', 'Responsive', 'Mobile first', 'UI kit']
                        }
                    ]}
                />
                <ServiceItem
                    title='Development'
                    description='Fluidity, performance and creative development are what we live for.'
                    image={webDevelopment}
                    items={[
                        {
                            title: 'Full Stack',
                            elems: ['Integration', 'Front-end', 'Back-end']
                        },
                        {
                            title: 'APIs',
                            elems: ['Stripe, Paypal...', 'Zendesk, Hubspot, Algolia, Twilio, Intercom...', 'Vimeo, Youtube, Ausha...', 'Mailchimp, Sendinblue...']
                        }
                    ]}
                />
                <ServiceItem
                    title='SEO'
                    description='The key to reaching, converting and retaining your users: the right message in the right place at the right time.'
                    items={[
                        {
                            title: 'Optimisation',
                            elems: ['Search engine rankings', 'Organic traffic', 'Online visibility']
                        }
                    ]}
                />
            </SectionWrapper>
            <Footer />
        </AppWrapper>
    )
}