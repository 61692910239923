import { Router } from 'react-router-dom';

export const baseUrl = 'https://web-olympus.com'

export const authorizedRequest = async (url, method, tokenType = 'accessToken', body) => {
	const token = localStorage.getItem(tokenType)
	
	const request = body ? {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body)
	} : {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		}
	}

	if (!token || token === '') {
		return undefined
	}

	const response = await fetch(url, request)

	if (response.status === 200 || response.status === 201) {
		return await response.json()
	}else if(response.status === 404){
		return response.status
	}

}

export const unauthorizedRequest = async (url, method, body) => {
	const request = body ? {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body)

	} : {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		}
	}

	const response = await fetch(url, request)

	if (response.status === 200) {
		return await response.json()
	} else {
		return response.status
	}
}


function App() {
  return (
    <Router/>
  );
}

export default App;
