import styled from "styled-components";

const ServiceItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media screen and (max-width: 1080px) {
        gap: 45px;
    }

    @media screen and (max-width: 800px) {
        gap: 30px;
    }
`

const ServiceItemHeader = styled.div`
    display: flex;
    gap: 150px;
    align-items: flex-start;

    @media screen and (max-width: 1080px) {
        gap: 112px;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 35px;
    }
`

const ServiceItemHeaderTitle = styled.h1`
    color: var(--text-primary);
    font-size: 60px;
    font-family: "Gamora";
    font-weight: 400;

    @media screen and (max-width: 1080px) {
        font-size: 45px;
    }

    @media screen and (max-width: 800px) {
        font-size: 30px;
    }

`

const ServiceItemHeaderDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media screen and (max-width: 1080px) {
        gap: 18px;
    }

    @media screen and (max-width: 800px) {
        gap: 12px;
    }
`
const ServiceItemHeaderDescriptionText = styled.p`
    color: var(--text-secondary);
    font-size: 16px;
`

const ServiceItemContentContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 60px;

    @media screen and (max-width: 1080px) {
        gap: 45px;
    }

    @media screen and (max-width: 800px) {
        gap: 30px;
        align-items: center;
        flex-direction: column;

        svg{
            display: none;
        }
    }
`

const ServiceItemContentItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justtify-content: space-between;
    width: 30%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`

const ServiceItemContentItemTitle = styled.h2`
    color: var(--text-primary);
    font-family: "Gamora";
    font-size: 40px;
    font-weight: 400;

    @media screen and (max-width: 1080px) {
        font-size: 30px;
    }

    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`

const ServiceItemContentItemList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: var(--brand-primary);
    margin-top: 35px;
    height: 225px;
    border-bottom: 1px solid var(--text-secondary);
    width: 100%;

    p{
        color: var(--text-primary);

    }

    @media screen and (max-width: 1080px) {
        gap: 18px;
        margin-top: 26px;
    }

    @media screen and (max-width: 800px) {
        gap: 12px;
        margin-top: 18px;
    }
`


export {
    ServiceItemContainer,
    ServiceItemContentContainer,
    ServiceItemContentItemContainer,
    ServiceItemContentItemList,
    ServiceItemContentItemTitle,
    ServiceItemHeader,
    ServiceItemHeaderDescriptionContainer,
    ServiceItemHeaderDescriptionText,
    ServiceItemHeaderTitle
}