import styled from "styled-components";

const FooterWrapper = styled.footer`
  width: 100%;
  margin-top: 200px;
  background-color: var(--footer-background);
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 62px 135px 26px 135px;
  }

  .socials {
    margin-top: 26px;
    display: flex;
    align-items: center;
    gap: 26px;

    a {
      color: var(--text-primary);
      transition: all 150ms ease;
      &:hover {
        color: var(--brand-primary);
        transition: all 150ms ease;
      }
    }
  }

  .footer-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-self: center;

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 25.2px;
      color: var(--text-primary);
    }

    li {
      margin-top: 4px;
      font-size: 15px;
      font-weight: 500;
      line-height: 33.75px;
      list-style: none;
      a {
        transition: all 150ms ease;
        color: var(--text-secondary);
        text-decoration: none;
        &:hover {
          color: var(--text-primary);
          transition: all 150ms ease;
        }
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    padding: 25px;

    p {
      font-size: 15px;
      font-weight: 400;
      color: var(--text-secondary);
    }
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: var(--text-primary);
    opacity: 0.15;
  }


  @media screen and (max-width: 1080px) {
    margin-top: 150px;

    .footer-links {
      padding: 37px 100px 19px 100px;
    }

  }

  @media screen and (max-width: 800px) {
    margin-top: 100px;

    .footer-links {
      padding: 31px 70px 13px 70px;
    }
  }
`;

export default FooterWrapper;