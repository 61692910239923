import styled from "styled-components";

const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    label {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.04ch;
        color: var(--text-primary);
    }

    .input-error{
        fonr-size: 16px;
        color: var(--text-primary);
    }

    textarea {
        width: 100%;
        height: 144px;
        padding: 15px;
        border: 1px solid var(--text-primary);
        background-color: transparent;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04ch;
        color: var(--text-primary);
        transition: all 200ms ease-in;
        resize: none;
        font-family: 'Plus Jajarta Sans', sans-serif;

        &:focus {
            border: 1px solid var(--brand-primary);
            outline: none;
        }

        &:hover {
            border: 1px solid var(--brand-primary);
        }
    }
`

export {
    TextAreaContainer
}