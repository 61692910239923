import Icon from "components/general/icon/icon";
import ButtonStyle from "./buttonStyled";
import buttonDecoration from "assets/images/button-decoration.svg";

export default function Button ({ onClick, arrow, children }) {
  return (
    <ButtonStyle onClick={onClick}>
      <img src={buttonDecoration} alt="" className="button-decoration" />
      <span>{children}</span>
      { arrow && <Icon name="arrow" style={{ width: '16px' }} /> }
      <img src={buttonDecoration} alt="" className="button-decoration" />
    </ButtonStyle>
  );
}