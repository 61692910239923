import Icon from "components/general/icon/icon";
import Logo from "components/general/logo/logo";
import FooterWrapper from "./footerStyled";
import { Link } from "react-router-dom"; 

export default function Footer () {
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <div className="footer-links">
        <div className="logo">
          <Logo size={33} />
          <div className="socials">
            <a href="https://www.instagram.com/web.olympus/" target="_blank" rel="noreferrer">
              <Icon name="instagram"/>
            </a>
            <a href="https://www.linkedin.com/company/web-olympus" target="_blank" rel="noreferrer">
              <Icon name="linkedin" />
            </a>
          </div>
        </div>
        <div className="quick-links footer-item">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/#about">About Us</Link></li>
            <li><a href="/contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div className="company footer-item">
          <h3>Company</h3>
          <ul>
            <li><a href="/services">Services</a></li>
          </ul>
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="copyright">
        <p>© {year} Web Olympus. All Rights Reserved</p>
      </div>
    </FooterWrapper>
  );
}