import styled from "styled-components";
import Nav from "components/general/nav/nav";
import Footer from "components/general/footer/footer";
import Header from "components/general/header/header";
import ContactUsContent from "components/contactUs/contactUsContent/contactUsContent";

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px;

  @media screen and (max-width: 1080px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 70px;
  }
`;


const AppWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--background-primary);
`;


export default function ContactUs() {
    return (
        <AppWrapper>
            <Nav />
            <Header title='Contact Us.' />
            <SectionWrapper>
                <ContactUsContent/>
            </SectionWrapper>
            <Footer />
        </AppWrapper>
    )
}