import Button from "components/general/button/button";
import {
  AboutLeft,
  AboutRight,
  AboutSection,
  Description
} from "./aboutStyled";
import SectionName from "../../general/sectionName/sectionNameStyled";
import SectionTitle from "../../general/sectionTitle/sectionTitleStyled";
import aboutBackground from "assets/images/about-background.svg";
import { useLocation } from "react-router-dom"; 
import { useEffect } from "react"; 

export default function About () {
  const location = useLocation()

  useEffect(()=> {
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
}, [location])

  return (
    <AboutSection id="about">
      <AboutLeft>
        <SectionName>ABOUT US</SectionName>
        <SectionTitle>We Create Digital World Class Business Agency Marketplace</SectionTitle>
        <img src={aboutBackground} alt="about-background"/>
      </AboutLeft>
      <AboutRight>
        <Description>
          <p>Welcome to Web Olympus, where innovation meets functionality, and your digital presence transforms into a powerful business asset. With a passion for creating stunning, user-centric websites and a commitment to elevating your online visibility, we are your dedicated partner in web redesign, web design and development, and SEO solutions.</p>
        </Description>
      </AboutRight>
    </AboutSection>
  )
}