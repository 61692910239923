import styled from "styled-components";
import InputField from "components/general/input/input";
import Header from "components/general/header/header";
import Button from "components/general/button/button";
import Nav from "components/general/nav/nav";
import Footer from "components/general/footer/footer";
import { useState } from "react";
import { baseUrl, unauthorizedRequest } from "App"; 

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 135px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1080px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 70px;
  }
`;



const AppWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--background-primary);
`;

export default function Register() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const register = async () => {
        const response = await unauthorizedRequest(baseUrl + '/api/register', 'POST', {
            email,
            password
        })

        if(response.token){
            localStorage.setItem('accessToken', response.token)
        }

    }

    return (
        <AppWrapper>
            <Nav />
            <Header title='Register'/>
            <SectionWrapper>
                <InputField value={email} label='email' placeholder='email' type='text' onChange={(e) => setEmail(e.target.value)}/>
                <InputField value={password} label='password' placeholder='password' type='password' onChange={(e) => setPassword(e.target.value)}/>
                <Button onClick={register}>Register</Button>
            </SectionWrapper>
            <Footer/>
        </AppWrapper>
    )
}