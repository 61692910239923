import styled from "styled-components";

const SectionTitle = styled.h3`
  font-family: "Gamora";
  font-size: 60px;
  font-weight: 400;
  line-height: 81px;
  color: var(--text-primary);

  @media screen and (max-width: 1080px) {
    font-size: 45px;
    line-height: 60px;
  }

  @media screen and (max-width: 800px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export default SectionTitle;