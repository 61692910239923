import InputFieldWrapper from "./inputStyled";

export default function InputField ({ value, label, name, placeholder, type, onChange, error }) {
  return (
    <InputFieldWrapper>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <p className="input-error" >{error}</p>}
    </InputFieldWrapper>
  );
}