import { HeaderContainer, HeaderTitle } from "./headerStyled";

export default function Header({ title }) {
    return (
        <HeaderContainer>
            <HeaderTitle>
                {title}
            </HeaderTitle>
        </HeaderContainer>
    )
}