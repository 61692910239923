import styled from "styled-components";

const PortfolioItemWrapper = styled.article`
  width: 100%;
  justify-content: center;
  max-width: 1440px;
  margin-top: 70px;
  display: flex;
  align-items: center;
  gap: 50px;
  overflow: scroll hidden;
  margin: 50px auto 0;

  @media screen and (max-width: 1080px) {
    gap: 38px;
  }
  
  @media screen and (max-width: 080px) {
    gap: 25px;
  }
  
  a {
    width: 430px;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default PortfolioItemWrapper