import styled from "styled-components";

const AboutSection = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 6px;
  margin-top: 200px;

  @media screen and (max-width: 1080px) {
    margin-top: 150px;
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }
`;

const AboutLeft = styled.div`
  max-width: 762px;
  grid-area: 1 / 1 / -1 / 9;
  
  img{
    width: 100%;
  }


  @media screen and (max-width: 1080px) {
    grid-area: 1 / 1 / 2 / -1;

    img {
      display: none;
    }
  }
`;

const AboutRight = styled.div`
  grid-area: 2 / 9 / -1 / -1;

  @media screen and (max-width: 1080px) {
    grid-area: 2 / 1 / -1 / -1;
  }
`;


const Description = styled.div`
  max-width: 370px;
  padding: 4px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.04ch;
    margin-bottom: 26px;
    color: var(--text-secondary);
  }

  @media screen and (max-width: 1080px) {
    max-width: 100%;
    grid-area: 2 / 1 / -1 / -1;
  }
`;

export {
  AboutSection,
  AboutLeft,
  AboutRight,
  Description
};