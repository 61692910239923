import Button from "components/general/button/button";
import Burger from "./burger/burger";
import Logo from "../logo/logo";
import { NavItem, NavLinks, NavStyle, NavLinksWrapper } from "./navStyled";
import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom"; 

export default function Nav () {
  const navigate = useNavigate()

  return (
    <NavStyle>
      <Logo />
      <NavLinksWrapper>
        <NavLinks>
          <NavItem onClick={() => {
            navigate('/services')
          }}>SERVICES</NavItem>
          <NavItem><Link to='/#portfolio'>PORTFOLIO</Link></NavItem>
          <NavItem><Link to='/#about'>ABOUT US</Link></NavItem>
        </NavLinks>
      </NavLinksWrapper>
      <NavLinksWrapper>
        <Button onClick={() => {
          navigate('/contact-us')
        }}>CONTACT US</Button>
      </NavLinksWrapper>
      <Burger/>
    </NavStyle>
  );
}