import { BurgerContainer, BurgerItem, BurgerNavLinks, BurgerNavbarContainer } from "./burgerStyled"
import { NavItem } from "../navStyled";
import { useState } from "react"; 
import { useNavigate } from "react-router-dom"; 
import Button from "components/general/button/button";
import { Link } from "react-router-dom";

export default function Burger () {
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };
  
    return (
        <>
            <BurgerContainer isOpen={false} onClick={handleClick}>
                <BurgerItem isOpen={false} />
                <BurgerItem isOpen={false} />
                <BurgerItem isOpen={false} />
            </BurgerContainer>
            <BurgerNavbarContainer isOpen={isOpen}>
                <BurgerContainer isOpen={true} onClick={handleClick}>
                    <BurgerItem isOpen={true} />
                    <BurgerItem isOpen={true} />
                    <BurgerItem isOpen={true} />
                </BurgerContainer>
                <BurgerNavLinks>
                    <NavItem onClick={() => {
                        navigate('/services')
                    }}>SERVICES</NavItem>
                    <NavItem><Link to='/#portfolio'>PORTFOLIO</Link></NavItem>
                    <NavItem><Link to='/#about'>ABOUT US</Link></NavItem>
                </BurgerNavLinks>
                <Button onClick={() => {
                    navigate('/contact-us')
                }}>CONTACT US</Button>
            </BurgerNavbarContainer>
        </>
    );

}