import styled from "styled-components";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 190px 0 70px 0;
    width: 100%;

    @media screen and (max-width: 1080px) {
        padding-top: 150px;
        padding-left: 75px;
        padding-right: 75px;
      }
    
      @media screen and (max-width: 800px) {
        padding-top: 100px;
      }
`;

const HeaderTitle = styled.h1`
    font-family: "Gamora";
    font-size: 140px;
    font-weight: 400;
    color: var(--text-primary);

    @media screen and (max-width: 1080px) {
        line-height: 90px;
        font-size: 75px;
    }

    @media screen and (max-width: 800px) {
        line-height: 60px;
        font-size: 50px;
    }
`;


export {
    HeaderContainer,
    HeaderTitle
};