import Icon from "../../general/icon/icon";
import SectionName from "../../general/sectionName/sectionNameStyled";
import SectionTitle from "../../general/sectionTitle/sectionTitleStyled";
import { ServiceSection, ServiceWrapper } from "./servicesStyled";

export default function Services () {
  return (
    <ServiceSection id="services">
      <SectionName>WHAT WE DO</SectionName>
      <SectionTitle>Our Core Services</SectionTitle>
      <ServiceWrapper>
        <div className="col">
          <a href="/services" className="row">
            <span>Web Design / Redesign</span>
            <Icon name="arrow" />
          </a>
          <hr className="horizontal-line" />
          <a href="/services" className="row">
            <span>Development</span>
            <Icon name="arrow" />
          </a>
          <hr className="horizontal-line" />
          <a href="/services" className="row">
            <span>SEO</span>
            <Icon name="arrow" />
          </a>
          <Icon name="semi-circle-revert" className="service-decoration" />
          <hr className="horizontal-line" />
        </div>
      </ServiceWrapper>
    </ServiceSection>
  );
}