import styled from "styled-components";

const BurgerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    @media screen and (min-width: 1080px) {
        display: none;
    }
`;

const BurgerItem = styled.div`
    width: 40px;
    height: 3px;
    background: #fff;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:nth-child(2) {
        opacity: ${(props) => (props.isOpen ? 0 : 1)};
        transition: opacity 0.3s ease;
        margin: 2.5px 0; /* Adjusted margin for centering */
    }

    &:first-child {
        transform-origin: 0% 0%;
        transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'none')};
        transition: transform 0.3s ease;
    }
    
    &:last-child {
        transform-origin: 0% 100%;
        transform: ${(props) => (props.isOpen ? 'rotate(-45deg)' : 'none')};
        transition: transform 0.3s ease;
    }
`

const BurgerNavbarContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background: var(--background-primary);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 320px;
    transition: width 0.3s ease;

    ${(props) => !props.isOpen && 'width: 0; padding: 20px 0'};

    @media screen and (min-width: 1080px) {
        display: none;
    }
`

const BurgerNavLinks = styled.ul`
    list-style: none;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`;

export {
    BurgerContainer,
    BurgerItem,
    BurgerNavbarContainer,
    BurgerNavLinks
};