import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Home from 'pages/home'
import ContactUs from 'pages/contactUs'
import Services from 'pages/services'
import Login from 'pages/logn'
import Register from 'pages/register'
import Contacts from 'pages/contacts'

const Router = () => {
	return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/services' element={<Services />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register/>} />
                <Route path='/contacts' element={<Contacts/>} />
            </Routes>
        </BrowserRouter>
	)
}

export default Router
