import PortfolioSection from "./portfolioStyled";
import SectionName from "../../general/sectionName/sectionNameStyled";
import SectionTitle from "../../general/sectionTitle/sectionTitleStyled";

export default function Portfolio () {
  return (
    <PortfolioSection id="portfolio">
      <div>
        <SectionName>PORTFOLIO</SectionName>
        <SectionTitle>Our Work</SectionTitle>
      </div>
    </PortfolioSection>
  );
}