import { TextAreaContainer } from "./textAreaStyled" 

export default function TextArea({ value, label, name, placeholder, onChange, error }) {
    return (
        <TextAreaContainer>
            {label && <label htmlFor={name}>{label}</label>}
            <textarea
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            {error && <p className="input-error" >{error}</p>}
        </TextAreaContainer>
    )
}