import styled from "styled-components";

const PortfolioSection = styled.section`
  width: 100%;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-container{
    width: 265px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 150px;
  }

  @media screen and (max-width: 800px) {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export default PortfolioSection;