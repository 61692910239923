import styled from "styled-components";

const LogoStyle = styled.p`
    color: var(--text-primary);
    font-size: ${props => props.size}px;
    font-weight: 600;
    letter-spacing: 0.1ch;
    line-height: 41px;
    display: flex;
    gap: 10px;
    cursor: pointer;
    
    div{
      display: flex;
      align-items: center;
    }


  @media screen and (max-width: 450px) {
    span {
      display: none;
    }
  }
`;

export default LogoStyle;